







































































































































































































































































































































































































































































































































































.stuExam{
.el-form {
    width: 100%;
    // padding-left: 160px;
    .form-item {
      width: 100%;
    }
  }
  .activityItem{
    background: #ccc;
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    border-radius:10px; 
    position: relative;
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
