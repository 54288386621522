.stuExam .el-form {
  width: 100%;
}
.stuExam .el-form .form-item {
  width: 100%;
}
.stuExam .activityItem {
  background: #ccc;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 0;
  border-radius: 10px;
  position: relative;
}
.stuExam .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stuExam .el-textarea .el-textarea__inner {
  resize: none;
}
 /deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}
